import Vue from 'vue';
import App from './App.vue';

import './assets/scss/app.scss';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

Vue.config.productionTip = false;

import store from './store';
import router from './router';

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
