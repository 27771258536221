export default {
    logo: 'Readromancefan',
    nav:{
        home: 'Home',
        unsubscribe: 'My subscription',
    },
    mailSent:'Your mail has been sent.',
    getInTouch:{
        title: 'GET IN TOUCH',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'E-mail',
        msg: 'Message',
        send: 'Send',
        cancelYourSubscription: 'CANCEL YOUR SUBSCRIPTION',
        inAOneClick: 'IN A ONE CLICK WITH',
        ourOnlineCancel: 'OUR ONLINE CANCELLATION SERVICE',
        text1: 'Our operators knows all the answers and will give you effective and useful advice in your case.',
        text2: 'Our subscription services offer you the solution of all the difference of your problems with our unique coaching programmes, assisting to the client and qualified expertise. We pursue one goal - to make your life bright and light.',
        desc1: 'Fast and efficient experts',
        desc2: 'Professional partners',
        desc3: 'Affinity Services Specialist',
        desc4: '100% free, no banking data',
    },
    questions:{
        title1: 'CUSTOMER SERVICE FOR ALL YOUR ONLINE SUBSCRIPTIONS',
        desc1: 'Easy management of your subscriptions. We offer you a practical management of the different subscriptions. Fill in the form with the e-mail address you used when you subscribed and you are in your customer account in one click. All the information is there to make your managing comfortable and give you maximum control of your subscriptions. You can also contact us to ask for help, unsubscribe or make a complaint. Everything is prepared to ensure that you can manage all the subscriptions in an easier way possible.',
        title2: 'AGENTS AT YOUR SERVICE',
        desc2: 'Our agents are experts in the after-sales services and have all the solutions of your problems. They work 24/7, so you can contact the agents by live chat or by phone via our toll free number any time you need. You can also send them an email to which they will respond within 48 hours. If you have problems in managing your subscription on ports or you have any other question, contact us.',
        faq: 'FAQ',
        faqList:[
            {
                id:1,
                show: false,
                question: 'What services you offer?',
                answer: 'Our service offers you the easiest way of managing your subscription. You only have to go to your customer account and fill in the form with an e-mail address. Our agents will contact you and you can obtain all the information concerning your subscription or cancel it.'
            },
            {
                id:2,
                show: false,
                question: 'I don’t remember signing up to your subscription.',
                answer: 'You have not signed up to our subscription. Probably you have signed up to a subscription at one of our partner sites for which we provide after-sales service. That is why you have the direct debits on your bank statement.'
            },
            {
                id:3,
                show: false,
                question: 'How can i find my chosen offer?',
                answer: 'If you want to know the details of your offer, just put your registering email address into the form at the homepage and then our agent will contact you and provide information you are interested in about your subscription.'
            },
            {
                id:4,
                show: false,
                question: 'I forgot my registered email address? What i can do?',
                answer: 'Our agents will help you with this problem. They will find your file, you just need to contact them by email, phone or chat.'
            },
            {
                id:5,
                show: false,
                question: 'I have a question, who can i contact with?',
                answer: 'Our friendly team works 24/7, so you can contact us anytime you need by chat or by phone. You can also send us the email with your question and we will respond within 48 hours.'
            },
            {
                id:6,
                show: false,
                question: 'How can i cancel my subscription?',
                answer: 'If you wish to cancel your subscription, you must go to your customer account and click on the button "Unsubscribe". But you will no longer benefit from the services of our site and will no longer be charged by the portals. A confirmation email will be sent.'
            },
            {
                id:7,
                show: false,
                question: 'I have some difficulties using member area, what should i do?',
                answer: 'If your difficulties are connected with the use of your personal account please contact our technical support. They will help you solve the problem that concerns you.'
            },
            {
                id:8,
                show: false,
                question: 'I do not recognize the transaction on my bank statement?',
                answer: 'If takeandlove.com is mentioned on your bank account or credit card statement this means that we have charged you on behalf of an online merchant and our customer service is at your disposal to help you solve your problem and respond to your request. You will need at least two of the following information: Credit Card, E-mail or Member Reference.'
            },
            {
                id:9,
                show: false,
                question: 'I want to cancel my registration? How can i do this?',
                answer: 'You have two ways to do this. You can do it yourself in your personal account or contact our service. For this operation, we will need some information: Username, Credit Card, E-mail or Member Reference.'
            },
            {
                id:10,
                show: false,
                question: 'I canceled my registration but still have access to the members area',
                answer: 'We will always send you a confirmation email after your cancellation. This ensures that we will not charge you for the next billing cycle. If you have received the cancellation email, it is sure that you will not be charged again. However, you will continue to have access to the member area until the end of the period you paid.'
            },
            {
                id:11,
                show: false,
                question: 'Can I send my credit card number by e-mail?',
                answer: 'Never send your credit card number by e-mail. This is confidential information. Always use our online forms for your own safety. If for some reason you can’t do this, call our Customer Service.'
            },
            {
                id:12,
                show: false,
                question: 'What is the CVV code and where can I find it?',
                answer: 'It is an additional security code to protect you from possible fraud and is printed on the back of your card. It consists of the last 3 numbers that follow the name of your account on the back of your credit card.'
            },
            {
                id:13,
                show: false,
                question: 'I forgot the web address of the site I bought. How can I get it back?',
                answer: 'To receive the web address by E-mail: - If you have at least two of the following details: Username, Credit Card, E-mail or Member Reference.'
            },
            {
                id:14,
                show: false,
                question: 'I have a question that FAQ does not answer. What can I do?',
                answer: 'If you have any problems or questions, we will be happy to hear from you and provide qualified assistance. Our team works for you and strives to provide you with top-quality services. Feel free to contact us!'
            },
            {
                id:15,
                show: false,
                question: 'I have trouble logging in to my account. Username and password do not work. What should I do?',
                answer: 'Make sure that you have entered your details exactly as indicated in the email confirmation. To receive the web address by E-mail we need your Username, Credit Card, E-mail or Member Reference.'
            },
        ],
        title3: 'WE ARE HERE TO MAKE YOUR LIFE BRIGHTER',
        subTitle3: 'stay with us and use',
        desc3: 'support 24/7, our experts knows what you are looking for',
        desc4: 'quick answer and solution of your request within 12 hours',
        desc5: 'absolutely free service',
        desc6: 'solutions that propose our experts are always clear simple and quick',
    },
    cancellation:{
        title1: 'CANCELLATION REQUEST',
        titleForm1: 'E-mail address used during the purchase',
        info1: 'We will not share this information with any third party',
        text1: 'By checking this box I allow to unsubscribe and delete my account from your database. This action will terminate any subsequent billing',
        address1: 'Bulgaria, Sofia',
        errorEmail: 'Enter valid email',
        workTime: 'Call center Works from 10 AM till 5 PM',
    }
}